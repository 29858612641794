@import "https://fonts.googleapis.com/css2?family=Parisienne&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  vertical-align: baseline;
  color: inherit;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.o-container {
  max-width: 1440px;
  margin-inline: auto;
}

.o-container--full {
  max-width: 1920px;
}

.o-image {
  width: 100%;
}

.o-arrow-down {
  transform: rotate(-90deg);
}

.o-icon {
  width: 12px;
}

.o-transition {
  transition: all .3s ease-in-out;
}

@keyframes discovermore_stroke {
  0% {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(0);
  }

  25% {
    transform-origin: top;
    transform: scaleY(1);
  }

  65% {
    transform-origin: bottom;
    transform: scaleY(1);
  }

  65.001% {
    transform-origin: bottom;
    transform: scaleY(1);
  }

  89% {
    transform-origin: bottom;
    opacity: 1;
    transform: scaleY(0);
  }

  89.001% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0);
  }
}

@keyframes flipAround {
  25% {
    transform-origin: 100%;
    animation-mode: forwards;
    transform: rotateY(-180deg);
  }

  25.001% {
    transform-origin: bottom;
    transform: translateX(200px);
  }

  50% {
    transform-origin: bottom;
    transform: translateX(200px)rotateX(-180deg);
  }

  50.001% {
    transform-origin: 0;
    transform: translateX(200px)translateY(200px);
  }

  75% {
    transform-origin: 0;
    transform: translateX(200px)translateY(200px)rotateY(180deg);
  }

  75.001% {
    transform-origin: top;
    transform: translateY(200px);
  }

  100% {
    transform-origin: top;
    transform: translateY(200px)rotateX(180deg);
  }
}

html, body {
  height: 100%;
  width: 100%;
}

#vale {
  height: 100%;
  width: 100%;
  color: #fff;
  letter-spacing: 0;
  font-family: Times;
  font-size: max(2.65487vw, 16px);
  line-height: 1.16667em;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  #vale {
    font-size: max(1.66667vw, 18px);
  }
}

#vale video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #vale video {
    display: none;
  }
}

#vale video.video__mob {
  display: block;
}

@media screen and (min-width: 768px) {
  #vale video.video__mob {
    display: none;
  }
}

#vale .container {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #0000004d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  #vale .container {
    width: 60%;
    text-align: inherit;
    background: linear-gradient(90deg, #4444444f 0%, #44444429 67.23%, #4440 100%);
    padding: 2em;
  }
}

#vale .container h1 {
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 3.47222vw;
  font-family: Parisienne;
  font-size: 9.33333vw;
  line-height: 1.44706em;
}

@media screen and (min-width: 768px) {
  #vale .container h1 {
    font-size: 5.90278vw;
  }
}

#vale .container p {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  display: flex;
}

@media screen and (max-width: 768px) {
  #vale .container p {
    width: auto;
    margin-inline: auto;
  }
}

#vale .container h3 {
  letter-spacing: 0;
  text-align: center;
  margin: 20px 0 40px;
  font-family: Times;
  font-size: 5.86667vw;
  font-weight: 400;
  line-height: 1.13333em;
}

@media screen and (min-width: 768px) {
  #vale .container h3 {
    font-size: 2.08333vw;
  }
}

#vale .container .buttons {
  width: 100%;
  justify-content: center;
  gap: 20px;
  display: flex;
}

#vale .container .buttons button {
  width: 40%;
  max-width: 300px;
  border: 3px solid #ffcb49;
  border-radius: 6px;
  padding: 12px;
  transition: all .3s ease-in-out;
}

#vale .container .buttons button:hover {
  background-color: #ffcb49;
}

#vale .modal, #vale .alert-iban {
  z-index: 2;
  width: 650px;
  height: 95vh;
  opacity: 1;
  background-color: #fff;
  border: 3px solid #ffcb49;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  gap: .4rem;
  padding: 1rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  #vale .modal, #vale .alert-iban {
    width: 95%;
    height: 95%;
  }
}

#vale .modal .btn-close, #vale .alert-iban .btn-close {
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  background: #ffcb49;
  border-radius: 50%;
  font-size: 1em;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
}

#vale .modal embed, #vale .alert-iban embed {
  width: 100%;
  min-width: 400px;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #vale .modal embed, #vale .alert-iban embed {
    min-width: auto;
  }
}

#vale .alert-iban {
  width: 650px;
  height: auto;
  color: #000;
  text-align: center;
  padding: 1.3rem;
  font-size: 1.5em;
}

@media screen and (max-width: 768px) {
  #vale .alert-iban {
    width: 90vw;
  }
}

#vale .alert-iban h2 {
  padding-bottom: 15px;
}

#vale .overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 1;
  opacity: 1;
  background: #00000080;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

#vale .hidden {
  pointer-events: none;
  cursor: default;
  opacity: 0;
}

/*# sourceMappingURL=index.7451c99d.css.map */
