.o {
    &-container {
        margin-inline: auto;
        max-width: 1440px;

        &--full {
            max-width: 1920px;
        }
    }

    &-image {
        width: 100%;
    }

    &-arrow-down {
        transform: rotate(-90deg);
    }

    &-icon {
        width: 12px;
    }

    &-transition {
        transition: all 0.3s ease-in-out;
    }
}

@keyframes discovermore_stroke {
    0% {
        transform: scaleY(0);
        opacity: 1;
        transform-origin: top;
    }
    25% {
        transform: scaleY(1);
        transform-origin: top;
    }
    65% {
        transform: scaleY(1);
        transform-origin: bottom;
    }
    65.001% {
        transform: scaleY(1);
        transform-origin: bottom;
    }

    89% {
        transform: scaleY(0);
        transform-origin: bottom;
        opacity: 1;
    }
    89.001% {
        transform: scaleY(0);
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
    }
}

@keyframes flipAround {
    25% {
        transform-origin: right;
        animation-mode: forwards;
        transform: rotateY(-180deg);
    }
    25.001% {
        transform-origin: bottom;
        transform: translateX(200px);
    }
    50% {
        transform-origin: bottom;
        transform: translateX(200px) rotateX(-180deg);
    }
    50.001% {
        transform-origin: left;
        transform: translateX(200px) translateY(200px);
    }
    75% {
        transform-origin: left;
        transform-origin: left;
        transform: translateX(200px) translateY(200px) rotateY(180deg);
    }
    75.001% {
        transform-origin: top;
        transform: translateY(200px);
    }
    100% {
        transform-origin: top;
        transform-origin: top;
        transform: translateY(200px) rotateX(180deg);
    }
}
