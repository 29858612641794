@import "./scss/object/reset.scss";
@import "./scss/object/variables.scss";
@import "./scss/object/mixins.scss";
@import "./scss/object/general.scss";
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

html,
body {
    height: 100%;
    width: 100%;
}

#vale {
    position: relative;
    height: 100%;
    width: 100%;
    color: white;
    font-family: Times;
    font-size: max(  vw(18px, 678px), 16px ) ;
    line-height: em(24px, 28px);
    letter-spacing: 0em;
    overflow: hidden;

    @media screen and (min-width: $mob) {
        font-size: max(  vw(24px, 1440px), 18px ) ;
    }

    video {
        object-fit: cover;
        width: 100%;
        height: 100%;

        @media screen and (max-width: $mob) {
            display: none;
        }

        &.video__mob {
            display: block;

            @media screen and (min-width: $mob) {
                display: none;
            }
        }
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);


        @media screen and (min-width: $mob) {
            padding: 2em;
            width: 60%;
            text-align: inherit;
            background: linear-gradient(90deg, rgba(68, 68, 68, 0.31) 0%, rgba(68, 68, 68, 0.16) 67.23%, rgba(68, 68, 68, 0) 100%);
        }

        h1 {
            font-family: Parisienne;
            font-size: vw(35px, 375px);
            line-height: em(85px, 123px);
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: vw(50px, 1440px);

            @media screen and (min-width: $mob) {
                font-size: vw(85px, 1440px);
            }

        }

        p {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            max-width: 600px;

            @media screen and (max-width: $mob) {
                width: auto;
                margin-inline:  auto;
            }
        }

        h3 {
            margin: 20px 0 40px;
            font-family: Times;
            font-size: vw(22px, 375px);
            line-height: em(30px, 34px);
            letter-spacing: 0em;
            text-align: center;
            font-weight: 400;

            @media screen and (min-width: $mob) {
                font-size: vw(30px, 1440px);
            }
        }

        .buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 20px;

            button {
                width: 40%;
                max-width: 300px;
                padding: 12px;
                border: 3px solid #FFCB49;
                border-radius: 6px;
                transition: all .3s ease-in-out;

                &:hover {
                    background-color: #FFCB49;
                }
            }
        }

    }


    .modal, .alert-iban {
        z-index: 2;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.4rem;
        width: 650px;
        height: 95vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 30px;
        border: 3px solid #FFCB49;
        opacity: 1;
        transition: all .3s ease-in-out;
        overflow: hidden; 

        @media screen and (max-width: $mob) {
            width: 95%;
            height:  95%;
        }

        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
            display: inline-block;
            font-weight: 700;
            color: white;
            text-align: center;
            font-size: 1em;
            width: 30px;
            height: 30px;
            background: #FFCB49;
            border-radius: 50%;

        }

        embed {
            width: 100%;
            min-width: 400px;
            height: 100%;
            
            @media screen and (max-width: $mob) {  
                min-width: auto;  
            }
        }

    }
    .alert-iban {
        width: 650px;
        height: auto;
        color: #000;
        text-align: center;
        font-size: 1.5em; 
        padding: 1.3rem;

        @media screen and (max-width: $mob) {
            width: 90vw; 
        }

        h2{
            padding-bottom:15px;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        z-index: 1;
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    .hidden {
        pointer-events: none;
        cursor: default;
        opacity: 0;
    }

    
}